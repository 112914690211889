<template>
  <El-Form-item prop="education">
    <El-Select v-model="model" class="w-full" placeholder="教育程度">
      <El-Option value="高中職">高中職</El-Option>
      <El-Option value="學士">學士</El-Option>
      <El-Option value="碩士">碩士</El-Option>
      <El-Option value="博士">博士</El-Option>
    </El-Select>
  </El-Form-item>
</template>
<script>
import { ElFormItem, ElSelect, ElOption } from 'element-plus';

import { useVModel } from '@vueuse/core';
export default {
  components: {
    ElFormItem,
    ElSelect,
    ElOption,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const model = useVModel(props, 'modelValue', emit);

    return { model };
  },
};
</script>
