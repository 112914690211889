<template>
  <el-upload
    class="avatar-uploader group"
    :action="apiUrl"
    :show-file-list="false"
    :on-success="onUploaded"
    :on-error="onError"
    :before-upload="start"
    :headers="{ AuthKey }"
    name="imgFile"
  >
    <!--  -->
    <div class="avatar" style="aspect-ratio: 1/1">
      <img v-if="url" class="w-full h-full object-cover" :src="url" />
      <img v-else src="@/assets/imgs/profile_photo.png" alt="" />
    </div>
    <div
      class="flex items-center justify-center bg-black bg-opacity-30 absolute inset-0 text-white duration-300 hover:opacity-100 opacity-0"
    >
      選擇照片
    </div>
  </el-upload>
</template>

<script>
import { ElUpload, ElMessage } from 'element-plus';
import { ref, onMounted, inject } from 'vue';
import User from '@/api/User';
import Storage from '@/utils/Storage';

export default {
  components: {
    ElUpload,
  },

  setup() {
    const { user, setUserData } = inject('auth');

    const apiUrl = process.env.VUE_APP_API_URL + '/consumer/v1/userImgUpd';
    const AuthKey = Storage.get('AuthKey');

    const url = ref('');
    const onUploaded = (res, file) => {
      if (res.imgUrl) {
        url.value = res.imgUrl;
        setUserData({ imgUrl: res.imgUrl });
      }
    };
    const onError = (res, file) => {
      const err = JSON.parse(res.message);

      ElMessage({ message: err.message });
    };

    const fetcher = () => {
      if (user && user.imgUrl) {
        url.value = user.imgUrl;
        return;
      }
      User.avatar().then((res) => {
        if (res.code === 1) {
          url.value = res.data.imgUrl;
        }
      });
    };
    onMounted(() => {
      fetcher();
    });
    const start = (file) => {
      let valid = true;
      console.log(file);
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        ElMessage.error('檔案格式不符(jpg,png)');
        valid = false;
      } else if (file.size / 1024 > 100) {
        ElMessage.error('檔案超過限制(100Kb)');
        valid = false;
      }
      return valid;
    };
    return { url, onUploaded, start, apiUrl, AuthKey, onError, user };
  },
};
</script>

<style scoped lang="sass">
.avatar-uploader
  :deep(.el-upload)
    cursor: pointer
    position: relative
    overflow: hidden
    min-width: 80px
    min-height: 80px
    border-radius: 100%
    &:hover
      border-color: #409eff

    .avatar
      img
        width: 100%
        display: block


.avatar-uploader-icon
  font-size: 28px
  color: #8c939d
  width: 178px
  height: 178px
  text-align: center

.avatar-uploader-icon svg
  margin-top: 74px /* (178px - 28px) / 2 - 1px */
</style>
