<template>
  <El-Form-item prop="birthday">
    <el-date-picker
      v-model="model"
      value-format="YYYY-MM-DD"
      type="date"
      style="width: 100%"
      placeholder="出生年月日"
    >
    </el-date-picker>
  </El-Form-item>
</template>
<script>
import { ElFormItem, ElDatePicker } from 'element-plus';

import { useVModel } from '@vueuse/core';
export default {
  components: {
    ElFormItem,
    ElDatePicker,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const model = useVModel(props, 'modelValue', emit);
    return { model };
  },
};
</script>
