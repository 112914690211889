import { state, commit } from '@/useStore';
import User from '@/api/User';
import { ElMessage } from 'element-plus';
import { ref, reactive } from 'vue';
const loading = ref(false);

export default () => {
  const form = reactive({
    name: '',
    gender: 'male',
    birthday: '',
    address: null,
    education: null,
    alert: 0,
  });

  const fetcher = () => {
    loading.value = true;
    return User.info()
      .then((res) => {
        if (res.code === 1) {
          Object.keys(res.data).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(form, key)) {
              form[key] = res.data[key];
            }
          });
        }
        return res;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const submit = () => {
    loading.value = true;
    return User.profileUpdate({ ...form })
      .then((res) => {
        if (res.code === 1) {
          ElMessage({ message: '成功更新', type: 'success' });
        } else {
          if (res.errorCode === '001') {
            ElMessage('該暱稱已經被使用。');
          }
        }
        return res;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return { loading, form, submit, fetcher };
};
