<template>
  <El-Form-item prop="address">
    <El-Input v-model="model" placeholder="地址" />
  </El-Form-item>
</template>
<script>
import { ElFormItem, ElInput } from 'element-plus';

import { useVModel } from '@vueuse/core';
export default {
  components: {
    ElFormItem,
    ElInput,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const model = useVModel(props, 'modelValue', emit);

    return { model };
  },
};
</script>
