<template>
  <El-Form-item prop="gender">
    <El-Radio-Group v-model="model">
      <El-Radio-Button label="male">男性</El-Radio-Button>
      <El-Radio-Button label="female">女性</El-Radio-Button>
    </El-Radio-Group>
  </El-Form-item>
</template>
<script>
import { ElFormItem, ElRadioGroup, ElRadioButton } from 'element-plus';

import { useVModel } from '@vueuse/core';
export default {
  components: {
    ElFormItem,
    ElRadioGroup,
    ElRadioButton,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const model = useVModel(props, 'modelValue', emit);

    return { model };
  },
};
</script>

<style lang="sass" scoped>
.el-radio-group
  width: 100%
  display: flex
  :deep(.el-radio-button)
    flex-grow: 1
    .el-radio-button__inner
      width: 100%
</style>
